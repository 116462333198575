@import '../../../_mixin';
@import '../../../_variables';

.contact {
  @include container;
  padding: 2rem 0;
  flex: 1;

  .contact__title {
    font-size: 2rem;
    font-weight: 800;
  }

  .contact__form {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .form__row {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(1, 1fr);

      @include sm {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
