$primary: #4b437e;
$primary-dark: #292547;
$secondary: #5ca4d9;
$secondary-dark: #447496;
$white: white;

$black-5: #f2f2f2;
$black-10: #e6e6e6;
$black-15: #d9d9d9;
$black-20: #cccccc;
$black-25: #bfbfbf;
$black-30: #b3b3b3;
$black-35: #a6a6a6;
$black-40: #999999;
$black-45: #8c8c8c;
$black-50: #808080;
$black-55: #737373;
$black-60: #666666;
$black-65: #595959;
$black-70: #4d4d4d;
$black-75: #404040;
$black-80: #333333;
$black-85: #262626;
$black-90: #1a1a1a;
$black-95: #0d0d0d;
$black-100: #000000;

$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1440px;

$container-width: 1280px;
