@import './_variables';

@mixin xs {
  @media screen and (min-width: $breakpoint-xs) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: $breakpoint-xl) {
    @content;
  }
}

@mixin container {
  max-width: $container-width;
  width: 95%;
  margin: auto;
  flex: 1;
}
