@import '../../../_mixin';
@import '../../../_variables';

.questionsHome {
  @include container;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;

  .questionHome__search {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-bottom: 1.5rem;
  }

  .questionHome__title {
    font-weight: 700;
    font-size: 2rem;
  }

  .questionHome__categories {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
  }
}
