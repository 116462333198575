@import '../../../_mixin';
@import '../../../_variables';

.questionsCategory {
  @include container;
  flex: 1;
  padding: 2rem 0;
  gap: 1rem;

  .questionsCategory__title {
    font-size: 1.5rem;
    font-weight: 600;

    @include md {
      font-size: 2rem;
    }

    span {
      font-style: italic;
      text-decoration: underline;
    }
  }

  .questionsCategory__questions {
    color: black;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1.5rem;
  }

  .questionsCategory__info {
    color: red;
    padding: 2rem 0;
    font-size: 1.5rem;
    font-weight: 800;
  }

  .questionsCategory__ask {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex: 1;
    padding-top: 2rem;
  }

  .questions__card {
    padding: 1rem 2rem;
    background-color: $secondary;
    color: white;
    border-radius: 0.5rem;
    transition: all 0.25s ease-out;
    cursor: pointer;

    &:hover {
      background-color: $secondary-dark;
    }

    &:active {
      opacity: 0.2;
    }
  }
}

.overlay {
  position: absolute;
  inset: 0;
  background-color: black;
  opacity: 0.25;
  z-index: 10;
}

.review__popup {
  padding: 2rem;
  background-color: white;
  border-radius: 0.5rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
  display: flex;
  width: 80%;
  max-width: 40rem;
  opacity: 1;
  flex-direction: column;
  gap: 1rem;

  .popup__title {
    font-size: 1.5rem;
  }
}
