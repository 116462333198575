@import '../../_mixin';
@import '../../_variables';

.home {
  @include container;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  padding: 2rem 0;

  .home__title {
    font-size: 2rem;
    font-weight: 800;
  }

  .home__text {
    font-weight: 600;
    opacity: 0.6;
  }
}
