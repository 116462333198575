@import '../../../_mixin';
@import '../../../_variables';

.medicId__container {
  @include container;
  padding: 2rem 0;

  .medicId__card {
    padding: 1rem;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    max-width: 40rem;
    gap: 2rem;
    border-radius: 0.5rem;

    .card__name {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 1.5rem;
      font-weight: 800;

      .card__verified {
        color: green;
      }

      .card__notverified {
        color: red;
      }
    }

    .card__body {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .card__info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 1.25rem;
        font-weight: 600;

        .info__value {
          font-weight: 600;
          opacity: 0.6;
        }
      }
    }

    .card__categories {
      font-weight: 700;
      font-size: 1.5rem;
      padding-top: 1rem;
    }

    .card__reviews {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .reviews__title {
        font-size: 1.5rem;
        font-weight: 800;
        // text-decoration: underline;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;

        span.count {
          font-size: 1rem;
          font-weight: 600;
          margin-left: -0.5rem;
          opacity: 0.4;
        }
      }

      .review {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        @include md {
          flex-direction: row;
          gap: 1.5rem;
        }

        .review__left {
          display: flex;
          flex-direction: row;
          align-items: end;
          justify-content: space-between;
          width: 100%;
          gap: 0.25rem;

          @include md {
            max-width: 10rem;
            flex-direction: column;
            justify-content: start;
          }

          .review__email {
            font-weight: 700;
          }
        }

        .review__right {
          flex: 1;
        }
      }

      .review__add {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding-top: 1rem;

        .add__header {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
          align-items: center;

          label {
            font-weight: 700;
          }
        }

        .add__body {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 0.5rem;
        }
      }
    }
  }
}
