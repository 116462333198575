@import '../../../_mixin';
@import '../../../_variables';

.pageLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: $primary;
  color: white;

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
