@import '../../_mixin';
@import '../../_variables';

.categories {
  @include container;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  padding: 2rem 0;

  .categories__title {
    font-size: 2rem;
    font-weight: 800;
    padding-bottom: 2rem;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 800;
  }

  .card__top {
    margin-top: -1rem;
  }

  .accordion__details {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .card__text {
    padding-top: 1rem;
  }

  .card__title {
    font-size: 1.5rem;
    font-weight: 800;
  }
}
