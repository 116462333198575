@import '../../../_mixin';
@import '../../../_variables';

.myProfie__home {
  padding: 2rem 0;
  @include container;

  .home__title {
    font-size: 2rem;
    font-weight: 800;
    color: $primary;
  }

  .myProfile__settings {
    color: black;
    display: flex;
    background-color: white;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 1.25rem;
    border-radius: 0.5rem;
  }
}
