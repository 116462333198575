@import '../../../_mixin';
@import '../../../_variables';

.medicHome {
  @include container;
  flex: 1;
  padding: 2rem 0;

  .medicHome__title {
    font-size: 2rem;
    font-weight: 600;
  }

  .medicHome__filter {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;

    .filter__title {
      font-size: 2rem;
      font-weight: 700;
    }

    .filter__body {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }

  .medicHome__all {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 2rem;

    .medicHome__card {
      background-color: $black-5;
      cursor: pointer;
      color: black;
      border-radius: 1rem;
      max-width: 40rem;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem 1.5rem;

      * {
        pointer-events: none;
      }

      .card__name {
        font-size: 1.5rem;
        font-weight: 800;
      }

      .card__verified {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
        color: green;
      }

      .card__notverified {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
        color: red;
      }

      .card__info {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        opacity: 0.6;
        margin: -0.25rem 0;
      }

      .card__rating {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
      }

      .card__categories {
        text-align: center;
      }
    }
  }
}
