@import '../../../../_mixin';
@import '../../../../_variables';

.adminCard {
  border-radius: 0.5rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: fit-content;
  padding: 0.25rem 0.35rem;

  @include md {
    padding: 1rem 1.25rem;
  }

  .adminCard__title {
    color: $primary;
    font-size: 1.5rem;
    font-weight: 700;
  }
}
