@import '../../../_mixin';
@import '../../../_variables';

.questionsQ__container {
  @include container;
  padding: 2rem 0;

  .container__title {
    font-size: 1.5rem;

    @include md {
      font-size: 2rem;
    }
    font-weight: 700;

    span {
      text-decoration: underline;
      font-style: italic;
    }
  }

  .container__search {
    padding-top: 1rem;
    width: 100%;
  }

  .container__search {
    padding-bottom: 2rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .container__questions {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .question__card {
      padding: 1rem 1.5rem;
      background-color: $secondary;
      transition: all 0.25s ease-out;
      border-radius: 0.5rem;

      &:hover {
        background-color: $secondary-dark;
      }

      &:active {
        opacity: 0.2;
      }
    }
  }
}
