@import '../../../_mixin';
@import '../../../_variables';

.login {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .login__card {
    padding: 1.25rem 1.5rem;
    background-color: $black-5;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 80%;
    max-width: 40rem;
    align-items: left;

    .login__title {
      font-size: 2rem;
      color: black;
      font-weight: 700;
    }

    .login__form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
    }

    .login__info {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;

      .info__item {
        color: black;
        font-size: 1rem;
        font-weight: 600;

        span {
          color: $primary;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
