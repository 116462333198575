@import '../../../_mixin';
@import '../../../_variables';

.register {
  flex: 1;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .register__card {
    padding: 1.25rem 1.5rem;
    background-color: $black-5;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 80%;
    max-width: 40rem;
    align-items: center;

    .register__title {
      font-size: 2rem;
      font-weight: 700;
    }

    .register__form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
    }

    .register__info {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;

      .info__item {
        font-size: 1rem;
        font-weight: 600;

        span {
          color: $primary;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
